<!--电梯模块--通行记录页--表格组件-->
<template>
  <div>
    <a-table
      :columns="columns"
      :dataSource="data"
      :scroll="scroll"
      :pagination="pagination"
      :loading="loading"
      @change = "onchange"
    >
     <span slot="result" slot-scope="text">
        <a-badge :status="text|statusTypeFilter" :text ="changeStaus(text)"></a-badge>
     </span>
    </a-table>
  </div>
</template>

<script>
import {
  tableSort
} from "@/utils/utils";
const statusMap = {
  1:{
    status:'success',
    text:'elevatorCardRecords.a19'
  },
  2:{
    status:'error',
    text:'elevatorCardRecords.a20'
  }
}
export default {
  name: "historyTable",
  props: {
    data: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    pagination: {
      type: Object,
    },
    scroll:{
      type: Object,
    }
  },
  data() {
    return {
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          width: 50,
          ellipsis: true,
          scopedSlots: { customRender: "NO" },
          fixed: "left",
        },
        {
          title: this.$t('elevatorCardRecords.a9'),
          dataIndex: "datetime",
          width: 160,
          sorter: (a, b) => {
            return tableSort(a, b, "datetime");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorCardRecords.a10'),
          dataIndex: "eventText",
          width: 100,
          sorter: (a, b) => {
            return tableSort(a, b, "eventText");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorCardRecords.a11'),
          dataIndex: "personName",
          width: 100,
          sorter: (a, b) => {
            return tableSort(a, b, "personName");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorCardRecords.a12'),
          dataIndex: "cardFaceNo",
          width: 140,
          sorter: (a, b) => {
            return tableSort(a, b, "cardFaceNo");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorCardRecords.a13'),
          dataIndex: "bodyTemperature",
          width: 70,
          sorter: (a, b) => {
            return tableSort(a, b, "bodyTemperature");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorCardRecords.a14'),
          dataIndex: "equipmentName1",
          width: 160,
          sorter: (a, b) => {
            return tableSort(a, b, "equipmentName1");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorCardRecords.a15'),
          dataIndex: "equipmentName2",
          width: 160,
          sorter: (a, b) => {
            return tableSort(a, b, "equipmentName2");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorCardRecords.a16'),
          dataIndex: "siteName",
          width: 180,
          sorter: (a, b) => {
            return tableSort(a, b, "siteName");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorCardRecords.a17'),
          dataIndex: "description",
          width: 140,
          sorter: (a, b) => {
            return tableSort(a, b, "description");
          },
          ellipsis: true,
        },
        {
          title: this.$t('elevatorCardRecords.a18'),
          dataIndex: "result",
          scopedSlots: { customRender: "result" },
          width: 120,
          sorter: (a, b) => {
            return tableSort(a, b, "result");
          },
          fixed: "right",
        },
      ],
    };
  },
  
  watch: {
  },
  methods: {
    onchange(pagination){
      this.$emit("update:pagination", pagination);
      this.$emit("refresh",false);
    },
    changeStaus(text){
      switch(text){
        case 1:return this.$t('elevatorCardRecords.a19') ;break;
        case 2:return this.$t('elevatorCardRecords.a20') ;break;
      }
    }
  },
  filters: {
    statusFilter(type,name){
      return statusMap[type].text
    },
    statusTypeFilter(type){
      return statusMap[type].status
    }
  }
};
</script>

<style scoped>

</style>